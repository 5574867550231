// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-tsx": () => import("./../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-quote-page-tsx": () => import("./../src/templates/quote-page.tsx" /* webpackChunkName: "component---src-templates-quote-page-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-privacy-page-tsx": () => import("./../src/templates/privacy-page.tsx" /* webpackChunkName: "component---src-templates-privacy-page-tsx" */),
  "component---src-templates-style-guide-page-tsx": () => import("./../src/templates/style-guide-page.tsx" /* webpackChunkName: "component---src-templates-style-guide-page-tsx" */),
  "component---src-templates-report-page-tsx": () => import("./../src/templates/report-page.tsx" /* webpackChunkName: "component---src-templates-report-page-tsx" */),
  "component---src-templates-renew-page-tsx": () => import("./../src/templates/renew-page.tsx" /* webpackChunkName: "component---src-templates-renew-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

